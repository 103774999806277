<template>
	<div class="dashboard">
    <div class="dashboard__container">
      <div class="dashboard__container--header pa-0">
        <h1>Privacy Policy</h1>
      </div>
      <div class="dashboard__container--body mb-5" style="width:auto;">
				<h3>Introduction</h3>
				<p>Jump Staffing created, develops, and maintains an online software and mobile platform on which businesses (“Partners”) and workers (“Professionals”) connect to fill one-time and/or recurring work opportunities. When you use our platform, including our websites, mobile applications, content, and services (collectively our “Services”), you share your information with us. Because we value your privacy, this policy (our “Privacy Policy”) describes how we collect, use, and share your personal data.

				Please carefully read this Privacy Policy, which we incorporate into our Terms of Use (“Terms”), located at https://jumpstaffing.com/terms. By accessing or using our Services in any capacity, you agree to our Terms and expressly authorize us to collect, receive, use, retain, and disclose or transmit your Information to third parties as described in this Privacy Policy.</p>
				<h3 class="mt-3">What We Collect</h3>
				<p>In this Privacy Policy, “we,” “us,” “our” and “Jump” all refer to Ridgeview Vista, Inc. dba Jump Staffing and our affiliates, and “you” and “your” refer to you, as a user of our Services as a Partner, Professional, Reference, or otherwise. Similarly, we reference the collective information you provide us as your “Information,” which is comprised of your Personal Information, Non-Personal Information, and Usage Data.</p>
				<p>“Personal Information” (sometimes called “Personally Identifiable Information”) refers to information which personally identifies you—whether alone or when combined with Usage Data and Non-Personal Information—including but not limited to your name, email address, social security number, mailing and/or physical address, mobile device number, telephone number, government identification number(s), date of birth, your photograph, and other information or data that identifies you.</p>
				<p>Personal Information and Personally Identifiable Information also include certain combinations of information that meet the applicable statutory or other legal definition of “Personally Identifiable Information” or “Personal Information” for purposes of breach notification (“Statutory PII”).</p>
				<p>“Non-Personal Information” refers to information that does not personally or specifically identify you, and may include aggregate or non-specific Usage Data and information about devices, operating systems, browsing activities, browsing histories, locations, dates and times of use of the Services, usage and traffic patterns, and other non-personally identifying analytics and information about users of our Services.</p>
				<h4 class="mt-3">Information You Give Directly to Us</h4>
				<p>Partners and Professionals use our Services to match available work opportunities with available workers who possess a relevant skill set, and the information we collect is intended to support this goal. To make these worker connections, both parties must register for and maintain an active Services account (a “Registered Account”). For example, if you post a shift on behalf of a Partner or create a related Registered Account, we may collect your name, email address, and other Personal Information about you and the Partner. Similarly, if you are a Professional, we may collect your name, email address, home address, and other Personal Information you submit as part of the registration, profile creation, or shift-matching process (including your resume, name and contact information for your References and related data pertinent to using the Services). Specifically, we collect information about you (including Personal Information) that you choose to submit to us in connection with your use of our Services, including information submitted when you:</p>
				<ul>
					<li>Create or update your Registered Account (including creating and updating information on your Professional or Partner profile), which, depending on your location and the Services you use, may include your name, email, social security number, mobile device number, phone number, username and password, address, government identification numbers, birth date and photographs</li>
					<li>Request certain services or features through the Services (such as through our mobile applications or websites)</li>
					<li>Contact Jump, including for support</li>
					<li>Contact other users (including Professionals, References or Partners) through our Services</li>
					<li>Work shifts as a Professional through our Services</li>
				</ul>
				<h4 class="mt-3">Information Created When You Use The Services</h4>
					<ul>
						<li>Location Information</li>
						<li>Usage and Preference Information</li>
						<li>Device Information</li>
						<li>Calls, Emails, and Text Messages</li>
					</ul>
				<h3 class="mt-3">
					Disclosure and Use
				</h3>
				<p>We may use your Personal Information for a variety of purposes, including to provide, enhance, personalize, maintain and improve our Services. Such use includes using your Personal Information to:</p>
				<ul>
					<li>Contact you</li>
					<li>Match Partners with Professionals</li>
					<li>Enable communications</li>
					<li>Assist you when you contact us for support</li>
					<li>Notify you of changes to our Services</li>
					<li>Conduct testing, research, analysis and product development to improve and enhance the safety and security of our Services</li>
					<li>Resolve disputes among users</li>
					<li>Solicit Feedback</li>
					<li>Communicate with you about products, services, promotions, studies, surveys, news, updates and events</li>
					<li>Promote and process contests and sweepstakes</li>
					<li>Perform internal operations necessary to provide our Services</li>
					<li>Investigate or address claims or disputes</li>
				</ul>
				<p>Except as otherwise provided in this Privacy Policy, we will not disclose your Personal Information to any third party unless the third party is a Permitted Recipient. You agree that we may disclose your Personal Information to the following “Permitted Recipients”: (1) persons or entities that you permit to access or receive your Personal Information (your permission being reasonably determined from your actions in view of the circumstances), including other users of the Services; (2) if you are a Partner, any persons or entities at your organization including those that may have created a Registered Account with us, and persons or entities designated by such organization; (3) if you are a Professional, Partners that have posted or may post shifts on or through our Services and for which you have accepted or worked a shift through the Services and/or relevant and/or related third parties as determined by Jump in its sole discretion; (4) References; (5) third-party providers of services to us such as Internet hosting providers, Internet services providers, email distribution and email management providers, and software development or maintenance providers, provided that we have a reasonable belief that such third-party providers will maintain your Personal Information in confidence; (6) third parties that provide services enabling us to market and advertise our Services and other content that has been customized for you through targeted advertising, targeted content and other means; and (7) any other person or entity to whom we are permitted or required to disclose your Personal Information by law, regulation or other provision of this Privacy Policy or the Terms.</p>
				<p>We also may disclose Non-Personal Information to third parties in a manner that does not identify a particular individual. For example, and not by way of limitation, we may disclose aggregated Personal Information (i.e., Non-Personal Information) to marketing and research partners and/or for advertising, marketing and promotional purposes. In addition, we may identify job posting organizations in our Services and in our advertising, marketing and promotional materials.</p>
				<h3 class="mt-3">Other Authorized Disclosures</h3>
				<p>Notwithstanding anything to the contrary in this Privacy Policy, you understand and agree that we may collect, use, preserve and disclose any information (including, without limitation, Usage Data and Personal Information): (1) in response to subpoenas, court orders, legal process or when otherwise required by law, or to establish, protect, or exercise our legal rights or defend against legal claims in our sole discretion; (2) to protect our rights and property or the public, including the enforcement of this Privacy Policy and any other agreement we may have with you or your organization (as applicable); (3) if we believe it is necessary in order to investigate, prevent, or take action regarding unauthorized distribution of another's intellectual property or information, illegal activities, fraud, or situations involving potential threats to the safety of any person (provided, however, that we have no obligation to do so); or (4) that is available to the general public.</p>
				<p>We may, from time to time, assign or transfer all or a portion of the Usage Data, Personal Information and/or Non-Personal Information and its rights and obligations hereunder to one or more third parties (whether in connection with a sale of assets, a merger or otherwise), in which case the successor or assignee will agree to abide by the obligations under this Privacy Policy with respect to such information transferred to such successor or assignee (unless otherwise agreed to by you and such successor or assignee).</p>
				<p>We may, from time to time, assign or transfer all or a portion of the Usage Data, Personal Information and/or Non-Personal Information and its rights and obligations hereunder to one or more third parties (whether in connection with a sale of assets, a merger or otherwise), in which case the successor or assignee will agree to abide by the obligations under this Privacy Policy with respect to such information transferred to such successor or assignee (unless otherwise agreed to by you and such successor or assignee).</p>
				<p>In addition to the other uses and disclosures of information set forth in this Privacy Policy, and notwithstanding anything in this Privacy Policy to the contrary, we may use, disclose, assign and transfer any Non-Personal Information for any purpose.</p>
				<h3 class="mt-3">Security</h3>
				<p>We and our service providers employ certain procedural and technological security measures that are reasonably designed to help protect your Personal Information from loss, unauthorized access, disclosure, alteration or destruction. Such measures, or any data transmission over the Internet or any network, cannot be guaranteed to be perfectly secure or effective. As a result, we cannot ensure or guarantee the security of any information you transmit to us or that is submitted on your behalf, and such information is transmitted and submitted at your own risk.</p>
				<p>You agree to keep your Personal Information private and confidential, and not to share information and materials that you receive by or through our Services with any third-party other than through the general, non-commercial methods permitted by the Services. In no event may Professionals or Partners share any information or materials with respect to their Account access and shift postings with any third-party.</p>
				<h3 class="mt-3">Third-Party Sites, Resources, Tools</h3>
				<p>Our Services may link to other websites (including, without limitation, recruiter websites) and Internet resources, or incorporate third-party tools, services, APIs, SDKs, and widgets that are not owned, operated or controlled by us. For example, we use Google Analytics, a web analytics service provided by Google, Inc. (“Google”). Google Analytics uses cookies to help us analyze how users use the Site and enhance your experience when you use the Service. For more information on how Google uses this data, go to www.google.com/policies/privacy/partners/. In addition, the Services (including Jump's websites and mobile applications) may contain advertisements, sponsorships and promotions of third parties. This Privacy Policy does not cover any information that may be collected from you by (a) such third-party websites and Internet resources or by any such third parties in connection with such advertisements, sponsorships and promotions; or (b) any such third-party tools, services, APIs, SDKs and widgets. You should be aware, however, that providers of third-party tools, services, APIs, SDKs and widgets, and advertisements, sponsorships and promotions in the Services may utilize cookies, web beacons and other mechanisms to collect information from you about your use of the Services and other websites in order to, among other things, provide additional functionality, advertisements, sponsorships and promotions about information, products or services that may be of interest to you. We are not responsible for any: (i) information, products or services available on or through any linked sites or resources, or through any such advertisements, sponsorships and promotions; (ii) third-party tools, services or widgets; or (iii) third-party terms of use, privacy policies or information collection, use and disclosure practices. You agree to review all applicable terms and use, privacy policies and other agreements provided by the applicable third parties.</p>
				<h3>Our Commitment To Children’s Privacy</h3>
				<p>By using the Services, you affirm that you are at least 18 years of age and that you are competent to accept and have accepted the terms and conditions of this Privacy Policy. The Services are not aimed at nor intended for children under sixteen (16) years of age. If you are under the age of sixteen (16), please do not use the Services. Instawork does not accept registration or profile creation from children under sixteen (16) years of age, and no one under the age of sixteen (16) may provide any information to or through the Services.</p>
				<h3>Disclosures for Residents of California</h3>
				<p>If you reside in California, the California Consumer Privacy Act (“CCPA”) provides you with additional rights to know, access, and delete your personal information. You also have the rights to opt out of the sale of your personal information by a business and to be free from discrimination for exercising your California privacy rights. To be clear, Instawork will not discriminate against you for exercising your privacy rights, nor do we “sell” your  personal information. However, if under the CCPA the term “sell” is interpreted to include sharing your personal information for personalized advertising, we will comply with the applicable law regarding such activity.</p>
				<p>Right to Know. You have the right to know the categories of Personal Information we have collected about you over the past 12 months, including:</p>
				<ul>
					<li>The categories of personal information we have collected about you;</li>
					<li>The categories of sources from which the personal information is collected;</li>
					<li>The business or commercial purpose for collecting and sharing your personal information;</li>
					<li>The categories of third parties with whom we have shared your personal information; and</li>
					<li>The specific pieces of personal information we have collected about you.</li>
				</ul>
				<p>California Law permits California residents to request certain information once per year regarding our disclosure of “personal information” (as that term is defined under applicable California law) to third parties for such third parties’ direct marketing purposes. To make such a request, please send written notice, by certified mail or Federal Express (signature required), noting that you are making a “California Privacy Request” to the following address:</p>
				<p>Jump Staffing<br />
					160 Alamo Plaza #486<br />
					Alamo, CA 94507
				</p>
				<h3 class="mt-3">Disclosures for Residents of Nevada</h3>
				<p>Under Nevada law, certain Nevada consumers may opt out of the sale of “personally identifiable information” for monetary consideration to a person for that person to license or sell such information to additional persons. “Personally identifiable information” includes first and last name, address, email address, phone number, Social Security Number, or an identifier that allows a specific person to be contacted either physically or online.</p>
			</div>
		</div>
	</div>
</template>


<script>
export default {
  name: 'privacy'
}
</script>